import React, { useEffect, useState } from "react";
import { graphql, StaticQueryDocument } from "gatsby";
import "../styles/general.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { CircleConfig } from "../components/ContentRowComponent/ContentRowComponent.types";
import { useWindowSize } from "../utils/useWindowSize";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";
import SeperatorComponent from "../components/SeperatorComponent/SeperatorComponent";

const PrivacyPage: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [circleConfigs, setCircleConfigs] = useState<CircleConfig[]>([
    {
      color: "rgb(233 233 233)",
      radius: 2000,
      top: 100,
      weight: 8,
      horiz: -700,
      active: true,
    },
    {
      color: "#f89a26",
      radius: 2000,
      top: -700,
      horiz: -700,
      weight: 8,
      active: true,
      left: true,
    },
  ]);

  /**
   *
   */
  useEffect(() => {
    switch (true) {
      case width > 600:
        setCircleConfigs([
          {
            color: "rgb(233 233 233)",
            radius: 2000,
            top: 100,
            weight: 8,
            horiz: -700,
            active: true,
          },
          {
            color: "#f89a26",
            radius: 2000,
            top: -700,
            horiz: -700,
            weight: 8,
            active: true,
            left: true,
          },
        ]);
        break;

      case width <= 600:
      default:
        setCircleConfigs([
          {
            active: false,
          },
          {
            active: false,
          },
        ]);
        break;
    }
  }, [width]);

  return (
    <LayoutComponent seo={{ title: t("page.privacy.title") }} sub>
      <ContentRowComponent circleConfig={circleConfigs[0]}>
        <div className="sub-page--content imprint">
          <div className="headline">Datenschutz</div>
          <div className="text">
            Insofern nachstehend keine anderen Angaben gemacht werden, ist die
            Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder
            vertraglich vorgeschrieben noch für einen Vertragsabschluss
            erforderlich. Sie sind zur Bereitstellung der Daten nicht
            verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt
            nur, soweit bei den nachfolgenden Verarbeitungsvorgängen keine
            anderweitige Angabe gemacht wird. "Personenbezogene Daten" sind alle
            Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person beziehen.
          </div>
          <div className="text">
            <b>Server Logfiles</b>
            <br />
            Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person
            zu machen. Bei jedem Zugriff auf unsere Website werden an uns oder
            unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren
            Internetbrowser übermittelt und in Protokolldaten (sog.
            Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören
            z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs,
            die IP-Adresse, die übertragene Datenmenge und der anfragende
            Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1
            lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der
            Gewährleistung eines störungsfreien Betriebs unserer Website sowie
            zur Verbesserung unseres Angebotes.
          </div>
          <div className="text">
            <b>Bestellungen</b>
            <br />
            Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei
            Bestellungen Bei der Bestellung erheben und verarbeiten wir Ihre
            personenbezogenen Daten nur, soweit dies zur Erfüllung und
            Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen
            erforderlich ist. Die Bereitstellung der Daten ist für den
            Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur
            Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung
            erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die
            Erfüllung eines Vertrags mit Ihnen erforderlich. Eine Weitergabe
            Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gewählten
            Versandunternehmen und Dropshipping Anbieter, Zahlungsdienstleister,
            Diensteanbieter für die Bestellabwicklung und IT-Dienstleister. In
            allen Fällen beachten wir strikt die gesetzlichen Vorgaben. Der
            Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.
          </div>
          <div className="text">
            <b>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars</b>
            <br />
            Bei der Nutzung des Kontaktformulars erheben wir Ihre
            personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur
            in dem von Ihnen zur Verfügung gestellten Umfang. Die
            Datenverarbeitung dient dem Zweck der Kontaktaufnahme. Mit Absenden
            Ihrer Nachricht willigen Sie in die Verarbeitung der übermittelten
            Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1
            lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung
            jederzeit durch Mitteilung an uns widerrufen, ohne dass die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung berührt wird. Ihre E-Mail-Adresse nutzen wir
            nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend
            gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung
            nicht zugestimmt haben.
          </div>
          <div className="text">
            <b>Datenerhebung bei Verfassung eines Kommentars</b>
            <br />
            Bei der Kommentierung eines Artikels oder eines Beitrages erheben
            wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
            Kommentartext) nur in dem von Ihnen zur Verfügung gestellten Umfang.
            Die Verarbeitung dient dem Zweck, eine Kommentierung zu ermöglichen
            und Kommentare anzuzeigen. Mit Absenden des Kommentars willigen Sie
            in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung
            erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer
            Einwilligung. Sie können die Einwilligung jederzeit durch Mitteilung
            an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
            Ihre personenbezogenen Daten werden anschließend gelöscht. Bei
            Veröffentlichung Ihres Kommentars wird nur der von Ihnen angegebene
            Name veröffentlicht. Darüber hinaus wird bei Abgabe des Kommentars
            Ihre IP-Adresse gespeichert zu dem Zweck, einen Missbrauch der
            Kommentarfunktion zu verhindern und die Sicherheit unserer
            informationstechnischen Systeme sicherzustellen. Mit Absenden des
            Kommentars willigen Sie in die Verarbeitung der übermittelten Daten
            ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a
            DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit
            durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
            berührt wird. Ihre IP-Adresse wird anschließend gelöscht.
          </div>
          <div className="text">
            <b>
              Verwendung der E-Mail-Adresse für die Zusendung von Newslettern
            </b>
            <br />
            Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung
            ausschließlich für eigene Werbezwecke zum Newsletterversand, sofern
            Sie dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf
            Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie
            können die Einwilligung jederzeit widerrufen, ohne dass die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung berührt wird. Sie können dazu den Newsletter
            jederzeit unter Nutzung des entsprechenden Links im Newsletter oder
            durch Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach
            aus dem Verteiler entfernt. Ihre Daten werden dabei an einen
            Dienstleister für E-Mail-Marketing im Rahmen einer
            Auftragsverarbeitung weitergegeben. Eine Weitergabe an sonstige
            Dritte erfolgt nicht. Ihre Daten werden an ein Drittland
            übermittelt, für welches ein Angemessenheitsbeschluss der
            Europäischen Kommission vorhanden ist.
          </div>
        </div>
      </ContentRowComponent>
      <ContentRowComponent
        circleConfig={circleConfigs[1]}
        className="minimal-z-index"
      >
        <SeperatorComponent height={20} />
      </ContentRowComponent>
    </LayoutComponent>
  );
};

export default PrivacyPage;

export const query: StaticQueryDocument = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    medicineParallaxImage: file(relativePath: { eq: "9010-medizin.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeParallaxImage: file(relativePath: { eq: "9010-zuhause.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jobParallaxImage: file(relativePath: { eq: "9010-beruf.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sportParallaxImage: file(relativePath: { eq: "9010-sport.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
